import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import dateUtilsConverters from '~/utils/date-utils-converters';
import { Icon, Text } from '~/ui/';

import './LiveRoutesMarkerPopup.scss';

function LiveRoutesMarkerPopup({ driverData, exceptionData }) {
    const { t } = useTranslation('translation');
    const currentStopNumber =
        (driverData.isCompleted && driverData.numStops) ||
        driverData.currentStopIndex;

    function getStatusTime() {
        if (driverData.isCompleted) {
            const { completedAt } = driverData.schedule[currentStopNumber];
            return t('completedTime', {
                time: DateTime.fromISO(completedAt).toFormat('t')
            });
        }

        const duration =
            dateUtilsConverters.convertMillisecondsToHoursAndMinutesAndSeconds(
                driverData.timeRemaining
            );
        return t('finishTime', {
            time: DateTime.local().plus(duration).toFormat('t')
        });
    }

    return (
        <div className="drivermarker-popup _fd-column _ai-flex-start">
            <div className="_fd-row _mb-3">
                {exceptionData.map((iconData) => {
                    return (
                        <div className="_fd-row _ai-center" key={iconData.icon}>
                            <Icon
                                key={iconData.position}
                                icon={iconData.icon}
                                color={iconData.iconColor}
                                size="s"
                            />
                            <Text
                                className="drivermarker-popup__exception-text"
                                color="galaxy-500"
                                variant="12-normal"
                            >
                                {iconData.value}
                            </Text>
                        </div>
                    );
                })}
            </div>
            <Text color="galaxy-800" variant="14-normal">
                {driverData.name}
            </Text>
            <div className="_fd-row _ai-center _my-2 ">
                <div className="_mr-1">
                    <Icon icon="stops" color="galaxy-500" size="s" />
                </div>
                <Text color="galaxy-500" variant="12-normal">
                    {t('driverCurrentStop', {
                        current: currentStopNumber,
                        total: driverData.numStops
                    })}
                </Text>
            </div>
            <div className="_fd-row _ai-center">
                <div className="_mr-1">
                    <Icon icon="clock2" size="s" />
                </div>
                <Text color="galaxy-500" variant="12-normal">
                    {getStatusTime()}
                </Text>
            </div>
        </div>
    );
}

export default LiveRoutesMarkerPopup;
