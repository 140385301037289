import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { selectActiveClientIds } from '~/reducers/activeClientsSlice';
import socketInstance from '~/utils/socket/socket-instance';
import { selectAppInitialized } from '~/reducers/appGlobalsSlice';
import {
    selectSelectedClientIds,
    selectSelectedClients
} from '~/reducers/selectedClientsSlice';

interface UseWebSocket {
    areAllSelectedClientsConnected: boolean;
    connectedClientIds: string[];
}

const useWebSocket = (): UseWebSocket => {
    const activeClientIds = useSelector(selectActiveClientIds);
    const selectedClientIds = useSelector(selectSelectedClientIds);

    const areAllSelectedClientsConnected = useMemo(() => {
        return isEqual(selectedClientIds.sort(), activeClientIds.sort());
    }, [activeClientIds, selectedClientIds]);

    return {
        connectedClientIds: activeClientIds,
        areAllSelectedClientsConnected
    };
};

const WebSocketProvider = () => {
    const selectedClients = useSelector(selectSelectedClients);
    const isAppInitialized = useSelector(selectAppInitialized);

    useEffect(() => {
        if (!isAppInitialized) {
            return;
        }

        const roomsToLeave = socketInstance.findRoomsToLeave(selectedClients);
        const roomsToJoin = socketInstance.findRoomsToJoin(selectedClients);

        socketInstance.leaveRooms(roomsToLeave);
        socketInstance.joinRooms(roomsToJoin);
    }, [selectedClients, isAppInitialized]);

    return null;
};

export { WebSocketProvider, useWebSocket };
