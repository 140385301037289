import _ from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { syncOnLogin } from '~/reducers/common-actions';
import constants, { isValueInConstantObject } from '~/utils/constants';
import type { RootState } from '~/store';
import type {
    ConfigurableMapRouteMode,
    MapMarkerMode,
    MapRouteMode,
    MapSettingsState,
    SetTabSpecificSettingAction
} from './types';
import { defaultGlobalMapSettings, stateObjectKeyMap } from './constants';
import { applyToPerTabSettings } from './utils';
import zonePolygonsReducers from './zonePolygonsReducers';

export const mapSettingsSlice = createSlice({
    name: 'mapSettings',
    initialState: defaultGlobalMapSettings,
    reducers: {
        ...zonePolygonsReducers,
        /**
         * set the current map mode setting for showing zone polygons
         * @method setShowZonePolygons
         * @param {MapSettingsState} state - the current state
         * @param {SetTabSpecificSettingAction} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowZonePolygons } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update zone polygons setting for plan route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowZonePolygons({ mode: constants.mapRouteModes.PLAN, value: true }));
         */
        setShowZonePolygons: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState => {
            const {
                payload: { mode, value: isShowZonePolygons }
            } = action;
            const {
                isZonePolygonsEditMode: isZonePolygonsEditModeKey,
                showZonePolygons: showZonePolygonsKey
            } = stateObjectKeyMap;
            const newState = applyToPerTabSettings(
                mode,
                showZonePolygonsKey,
                state,
                isShowZonePolygons
            );

            if (isShowZonePolygons) return newState;

            return applyToPerTabSettings(
                mode,
                isZonePolygonsEditModeKey,
                newState,
                false
            );
        },
        /**
         * set the current map mode setting for showing route polygons
         * @method setShowRoutePolygons
         * @param {MapSettingsState} state - the current state
         * @param {SetTabSpecificSettingAction} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowRoutePolygons } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update route polygons setting for plan route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowRoutePolygons({ mode: constants.mapRouteModes.PLAN, value: true }));
         */
        setShowRoutePolygons: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showRoutePolygons',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing route popups
         * @method setShowRoutePopup
         * @param {MapSettingsState} state - the current state
         * @param {SetTabSpecificSettingAction} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowRoutePopup } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update route popups setting for plan route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowRoutePopup({ mode: constants.mapRouteModes.PLAN, value: true }));
         */
        setShowRoutePopup: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showRoutePopup',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing route labels
         * @method setShowRouteLabel
         * @param {MapSettingsState} state - the current state
         * @param {SetTabSpecificSettingAction} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowRouteLabel } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update route labels setting for plan route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowRouteLabel({ mode: constants.mapRouteModes.PLAN, value: true }));
         */
        setShowRouteLabel: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showRouteLabel',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing stop popups
         * @method setShowStopPopup
         * @param {MapSettingsState} state - the current state
         * @param {SetTabSpecificSettingAction} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowStopPopup } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update stop popups setting for plan route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowStopPopup({ mode: constants.mapRouteModes.PLAN, value: true }));
         */
        setShowStopPopup: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showStopPopup',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing stop numbers
         * @method setShowStopNumber
         * @param {MapSettingsState} state - the current state
         * @param {SetTabSpecificSettingAction} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowStopNumber } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update stop numbers setting for plan route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowStopNumber({ mode: constants.mapRouteModes.PLAN, value: true }));
         */
        setShowStopNumber: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showStopNumber',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing stop labels
         * @method setShowStopLabel
         * @param {MapSettingsState} state - the current state
         * @param {SetTabSpecificSettingAction} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowStopLabel } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update stop labels setting for plan route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowStopLabel({ mode: constants.mapRouteModes.PLAN, value: true }));
         */
        setShowStopLabel: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showStopLabel',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing driver popups
         * @method setShowLiveRoutePopup
         * @param {MapSettingsState} state - the current state
         * @param {SetTabSpecificSettingAction} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowLiveRoutePopup } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update driver popups setting for dispatched route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowLiveRoutePopup({ mode: constants.mapRouteModes.DISPATCHED, value: true }));
         */
        setShowLiveRoutePopup: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showLiveRoutesPopup',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing driver labels
         * @method setShowLiveRouteLabel
         * @param {MapSettingsState} state - the current state
         * @param {SetTabSpecificSettingAction} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowLiveRouteLabel } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update driver labels setting for dispatched route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowLiveRouteLabel({ mode: constants.mapRouteModes.DISPATCHED, value: true }));
         */
        setShowLiveRouteLabel: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showLiveRoutesLabel',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing driver labels
         * @method setShowDriverName
         * @param {MapSettingsState} state - the current state
         * @param {SetTabSpecificSettingAction} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowDriverName } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update driver labels setting for dispatched route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowDriverName({ mode: constants.mapRouteModes.DISPATCHED, value: true }));
         */
        setShowDriverName: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showDriverName',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing driver labels
         * @method setShowVehicleEid
         * @param {MapSettingsState} state - the current state
         * @param {SetTabSpecificSettingAction} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowVehicleEid } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update driver labels setting for dispatched route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowVehicleEid({ mode: constants.mapRouteModes.DISPATCHED, value: true }));
         */
        setShowVehicleEid: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showVehicleEid',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing driver lines
         * @method setShowDriverLines
         * @param {MapSettingsState} state - the current state
         * @param {SetTabSpecificSettingAction} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowDriverLines } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update driver lines setting for dispatched route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowDriverLines({ mode: constants.mapRouteModes.DISPATCHED, value: true }));
         */
        setShowDriverLines: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showDriverLines',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing equipment marker
         * @method setShowEquipmentMarker
         * @param {MapSettingsState} state - the current state
         * @param {SetTabSpecificSettingAction} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowEquipmentMarker } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // update equipment marker setting for dispatched route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowEquipmentMarker({ mode: constants.mapRouteModes.DISPATCHED, value: true }));
         */
        setShowEquipmentMarker: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showEquipmentMarker',
                state,
                action.payload.value
            ),

        /**
         * set the current map mode setting enabling unassigned tasks cluster mode
         * @method setIsClusteringUnassignedTasks
         * @param {MapSettingsState} state - the current state
         * @param {SetTabSpecificSettingAction} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setIsClusteringUnassignedTasks } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // update unassigned tasks cluster mode setting for dispatched route mode to `true`
         * // this setting is applicable for both plan mode and dispatched route mode
         * // however its value is independent for each route mode
         * const dispatch = useDispatch();
         * dispatch(setIsClusteringUnassignedTasks({ mode: constants.mapRouteModes.DISPATCHED, value: true }));
         */
        setIsClusteringUnassignedTasks: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState => {
            return applyToPerTabSettings(
                action.payload.mode,
                'isClusteringUnassignedTasks',
                state,
                action.payload.value
            );
        },
        /**
         * set the global map setting whether the map has isolated routes
         * @method setHasIsolatedRoutes
         * @param {MapSettingsState} state - the current state
         * @param {PayloadAction<boolean>} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setHasIsolatedRoutes } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // update isolated routes setting to `true`
         * const dispatch = useDispatch();
         * dispatch(setHasIsolatedRoutes(true);
         */
        setHasIsolatedRoutes: (
            state: MapSettingsState,
            action: PayloadAction<boolean>
        ): MapSettingsState => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            return { ...state, hasIsolatedRoutes: action.payload };
        },
        /**
         * set the global map setting identifying the map marker mode
         * @method setMapMarkerMode
         * @param {MapSettingsState} state - the current state
         * @param {PayloadAction<MapMarkerMode>} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setMapMarkerMode } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update map marker mode to `STOPS_CLUSTERS`
         * const dispatch = useDispatch();
         * dispatch(setMapMarkerMode(constants.mapMarkerModes.STOPS_CLUSTERS));
         */
        setMapMarkerMode: (
            state: MapSettingsState,
            action: PayloadAction<MapMarkerMode>
        ): MapSettingsState => {
            if (
                !isValueInConstantObject<MapMarkerMode>(
                    'mapMarkerModes',
                    action.payload
                )
            ) {
                return state;
            }
            return { ...state, mapMarkerMode: action.payload };
        },
        /**
         * set the global map setting identifying the map route mode
         * @method setMapRouteMode
         * @param {MapSettingsState} state - the current state
         * @param {PayloadAction<MapRouteMode>} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setMapRouteMode } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update map route mode to `COMPLETED`
         * const dispatch = useDispatch();
         * dispatch(setMapRouteMode(constants.mapRouteModes.COMPLETED));
         */
        setMapRouteMode: (
            state: MapSettingsState,
            action: PayloadAction<MapRouteMode>
        ): MapSettingsState => {
            if (
                !isValueInConstantObject<MapRouteMode>(
                    'mapRouteModes',
                    action.payload
                )
            ) {
                return state;
            }
            return { ...state, mapRouteMode: action.payload };
        },
        /**
         * replace the global map settings
         * @method replaceMapSettings
         * @param {MapSettingsState} state - the current state
         * @param {PayloadAction<MapSettingsState>} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { replaceMapSettings } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * const dispatch = useDispatch();
         * dispatch(replaceMapSettings(mapSettings));
         */
        replaceMapSettings: (state, action) => {
            return action.payload;
        },
        /**
         * set the global map setting whether the map should fit markers to map bounds
         * @method setShouldFitPlanMapToBounds
         * @param {MapSettingsState} state - the current state
         * @param {PayloadAction<boolean>} action - the reducer's action object
         * @param {MapSettingsState} action.payload - the reducer's payload
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShouldFitPlanMapToBounds } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // set markers to fit to bounds
         * const dispatch = useDispatch();
         * dispatch(setShouldFitPlanMapToBounds(true));
         */
        setShouldFitPlanMapToBounds: (
            state: MapSettingsState,
            action: PayloadAction<boolean>
        ): MapSettingsState => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            return { ...state, shouldFitPlanMapToBounds: action.payload };
        },
        /**
         * set the global map setting whether the user was redirected to map page
         * @method setRedirectedToMapPage
         * @param {MapSettingsState} state - the current state
         * @param {PayloadAction<boolean>} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setRedirectedToMapPage } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // identify that the user was redirected to map page
         * const dispatch = useDispatch();
         * dispatch(setRedirectedToMapPage(true));
         */
        setRedirectedToMapPage: (
            state: MapSettingsState,
            action: PayloadAction<boolean>
        ): MapSettingsState => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            state.redirectedToMapPage = action.payload;
            return state;
        },
        /**
         * set the current map mode setting enabling clustering stops
         * @method setIsClusteringStops
         * @param {MapSettingsState} state - the current state
         * @param {SetTabSpecificSettingAction} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setIsClusteringStops } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // identify that the map is clustering stops
         * const dispatch = useDispatch();
         * dispatch(setIsClusteringStops({ mode: constants.mapRouteModes.DISPATCHED, value: true }));
         */
        setIsClusteringStops: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState => {
            return applyToPerTabSettings(
                action.payload.mode,
                'isClusteringStops',
                state,
                action.payload.value
            );
        },
        /**
         * set the global map setting whether the map clustering toggle is enabled
         * @method setIsClusteringToggleEnabled
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {boolean} action.payload - the reducer's payload
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setIsClusteringToggleEnabled } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // identify that the map clustering toggle is enabled
         * const dispatch = useDispatch();
         * dispatch(setIsClusteringToggleEnabled(true));
         */
        setIsClusteringToggleEnabled: (
            state: MapSettingsState,
            action: PayloadAction<boolean>
        ): MapSettingsState => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            state.isClusteringToggleEnabled = action.payload;
            return state;
        },
        /**
         * set the global map setting whether the map is allowing multiple cards to be selected from the drawers
         * @method setIsMultipleCardSelectEnabled
         * @param {MapSettingsState} state - the current state
         * @param {PayloadAction<boolean>} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setIsMultipleCardSelectEnabled } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // identify that the map is allowing multiple cards to be selected from the drawers
         * const dispatch = useDispatch();
         * dispatch(setIsMultipleCardSelectEnabled(true));
         */
        setIsMultipleCardSelectEnabled: (
            state: MapSettingsState,
            action: PayloadAction<boolean>
        ): MapSettingsState => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            state.isMultipleCardSelectEnabled = action.payload;
            return state;
        },
        /**
         * set the global map setting whether the map drawer is set to view card details
         * @method setViewCardDetails
         * @param {MapSettingsState} state - the current state
         * @param {PayloadAction<boolean>} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setViewCardDetails } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // identify the map drawer is set to view card details
         * const dispatch = useDispatch();
         * dispatch(setViewCardDetails(true));
         */
        setViewCardDetails: (
            state: MapSettingsState,
            action: PayloadAction<boolean>
        ): MapSettingsState => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            state.viewCardDetails = action.payload;
            return state;
        },

        /**
         * set the global map setting that determines currently active equipment cluster id
         * @method setActiveEquipmentClusterId
         * @param {MapSettingsState} state - the current state
         * @param {PayloadAction<number>} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setActiveEquipmentClusterId } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // identify the correct cluster id is active
         * const dispatch = useDispatch();
         * dispatch(setActiveEquipmentClusterId(clusterId));
         */
        setActiveEquipmentClusterId: (
            state: MapSettingsState,
            action: PayloadAction<number>
        ): MapSettingsState => {
            state.activeEquipmentClusterId = action.payload;
            return state;
        },

        /**
         * set the current map mode setting for showing driver actual lines
         * @method setShowDriverActualLines
         * @param {MapSettingsState} state - the current state
         * @param {SetTabSpecificSettingAction} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowDriverActualLines } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update driver actual lines setting for dispatched route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowDriverActualLines({ mode: constants.mapRouteModes.DISPATCHED, value: true }));
         */
        setShowDriverActualLines: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState =>
            applyToPerTabSettings(
                action.payload.mode,
                'showDriverActualLines',
                state,
                action.payload.value
            ),

        /**
         * set the current map mode setting enabling equipment cluster mode
         * @method setIsClusteringEquipment
         * @param {MapSettingsState} state - the current state
         * @param {SetTabSpecificSettingAction} action - the reducer's action object
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setIsClusteringEquipment } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // update equipment cluster mode setting for dispatched route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setIsClusteringEquipment({ mode: constants.mapRouteModes.DISPATCHED, value: true }));
         */
        setIsClusteringEquipment: (
            state: MapSettingsState,
            action: SetTabSpecificSettingAction
        ): MapSettingsState => {
            return applyToPerTabSettings(
                action.payload.mode,
                'isClusteringEquipment',
                state,
                action.payload.value
            );
        },
        /**
         * Reset most global map settings back to default values, persisting select settings
         * @method resetMapState
         * @param {MapSettingsState} state the current state
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { resetMapState } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // reset map state
         * const dispatch = useDispatch();
         * dispatch(resetMapState());
         */
        resetMapState: (state: MapSettingsState): MapSettingsState => {
            const persistSettings = _.pick(state, [
                constants.mapRouteModes.PLAN,
                constants.mapRouteModes.DISPATCHED,
                constants.mapRouteModes.COMPLETED,
                'isClusteringToggleEnabled',
                'isClusteringStops',
                'mapRouteMode'
            ]);
            return {
                ...defaultGlobalMapSettings,
                ...persistSettings
            };
        },
        /**
         * reset the all global map settings back to default values
         * @method resetMapSettings
         * @returns {MapSettingsState} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { resetMapSettings } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // reset map settings
         * const dispatch = useDispatch();
         * dispatch(resetMapSettings());
         */
        resetMapSettings: (): MapSettingsState => {
            return defaultGlobalMapSettings;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(syncOnLogin, (state) => {
            const { plan, dispatched, completed } = defaultGlobalMapSettings;
            const {
                plan: currentPlan,
                dispatched: currentDispatched,
                completed: currentCompleted
            } = state;

            // merge to initial state to ensure new keys for this slice
            // are synced with the current state
            return {
                ...defaultGlobalMapSettings,
                ...state,
                plan: {
                    ...plan,
                    ...currentPlan
                },
                dispatched: {
                    ...dispatched,
                    ...currentDispatched
                },
                completed: {
                    ...completed,
                    ...currentCompleted
                }
            };
        });
    }
});

export const {
    setShowZonePolygons,
    setShowRoutePolygons,
    setShowRoutePopup,
    setShowRouteLabel,
    setShowStopPopup,
    setShowStopNumber,
    setShowStopLabel,
    setShowLiveRoutePopup,
    setShowLiveRouteLabel,
    setShowDriverName,
    setShowVehicleEid,
    setShowDriverLines,
    setShowEquipmentMarker,
    setHasIsolatedRoutes,
    setMapMarkerMode,
    setMapRouteMode,
    setShouldFitPlanMapToBounds,
    setRedirectedToMapPage,
    setIsClusteringStops,
    setIsClusteringToggleEnabled,
    setIsMultipleCardSelectEnabled,
    setViewCardDetails,
    replaceMapSettings,
    resetMapSettings,
    resetMapState,
    setIsZonePolygonsEditMode,
    setShowDriverActualLines,
    setIsClusteringUnassignedTasks,
    setIsClusteringEquipment,
    setActiveEquipmentClusterId
} = mapSettingsSlice.actions;

/**
 * selects the current map mode setting for showing zone polygons
 * @method
 * @param {ConfigurableMapRouteMode} mapRouteMode
 * @returns {boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowZonePolygons } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showZonePolygons = useSelector(selectShowZonePolygons(constants.mapRouteModes.PLAN));
 */
export const selectShowZonePolygons =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showZonePolygons;

export const selectIsZonePolygonsEditMode =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.[
            stateObjectKeyMap.isZonePolygonsEditMode
        ];

/**
 * selects the current map mode setting for showing route polygons
 * @method
 * @param {ConfigurableMapRouteMode} mapRouteMode
 * @returns {boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowRoutePolygons } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showRoutePolygons = useSelector(selectShowRoutePolygons(constants.mapRouteModes.PLAN));
 */
export const selectShowRoutePolygons =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showRoutePolygons;

/**
 * selects the current map mode setting for showing route popups
 * @method
 * @param {ConfigurableMapRouteMode} mapRouteMode
 * @returns {boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowRoutePopup } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showRoutePopup = useSelector(selectShowRoutePopup(constants.mapRouteModes.PLAN));
 */
export const selectShowRoutePopup =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showRoutePopup;

/**
 * selects the current map mode setting for showing route labels
 * @method
 * @param {ConfigurableMapRouteMode} mapRouteMode
 * @returns {boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowRouteLabel } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showRouteLabel = useSelector(selectShowRouteLabel(constants.mapRouteModes.PLAN));
 */
export const selectShowRouteLabel =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showRouteLabel;

/**
 * selects the current map mode setting for showing stop popups
 * @method
 * @param {ConfigurableMapRouteMode} mapRouteMode
 * @returns {boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowStopPopup } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showStopPopup = useSelector(selectShowStopPopup(constants.mapRouteModes.PLAN));
 */
export const selectShowStopPopup =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showStopPopup;

/**
 * selects the current map mode setting for showing stop numbers
 * @method
 * @param {ConfigurableMapRouteMode} mapRouteMode
 * @returns {boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowStopNumber } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showStopNumber = useSelector(selectShowStopNumber(constants.mapRouteModes.PLAN));
 */
export const selectShowStopNumber =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showStopNumber;

/**
 * selects the current map mode setting for showing stop labels
 * @method
 * @param {ConfigurableMapRouteMode} mapRouteMode
 * @returns {boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowStopLabel } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showStopLabel = useSelector(selectShowStopLabel(constants.mapRouteModes.PLAN));
 */
export const selectShowStopLabel =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showStopLabel;

/**
 * selects the current map mode setting for showing driver popups
 * @method
 * @param {ConfigurableMapRouteMode} mapRouteMode
 * @returns {boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowDriverPopup } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showDriverPopup = useSelector(selectShowDriverPopup(constants.mapRouteModes.DISPATCHED));
 */
export const selectShowLiveRoutePopup =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showLiveRoutesPopup;

/**
 * selects the current map mode setting for showing driver labels
 * @method
 * @param {ConfigurableMapRouteMode} mapRouteMode
 * @returns {boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowDriverLabel } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showLiveRouteLabel = useSelector(selectShowLiveRouteLabel(constants.mapRouteModes.DISPATCHED));
 */
export const selectShowLiveRouteLabel =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showLiveRoutesLabel;

/**
 * selects the current map mode setting for showing driver lines
 * @method
 * @param {ConfigurableMapRouteMode} mapRouteMode
 * @returns {boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowDriverName } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showDriverName = useSelector(selectShowDriverName(constants.mapRouteModes.DISPATCHED));
 */
export const selectShowDriverName =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showDriverName;

/**
 * selects the current map mode setting for showing driver lines
 * @method
 * @param {ConfigurableMapRouteMode} mapRouteMode
 * @returns {boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowVehicleEid } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showVehicleEid = useSelector(selectShowVehicleEid(constants.mapRouteModes.DISPATCHED));
 */
export const selectShowVehicleEid =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showVehicleEid;

/**
 * selects the current map mode setting for showing driver lines
 * @method
 * @param {ConfigurableMapRouteMode} mapRouteMode
 * @returns {boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowDriverLines } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showDriverLines = useSelector(selectShowDriverLines(constants.mapRouteModes.DISPATCHED));
 */
export const selectShowDriverLines =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showDriverLines;

/**
 * selects the current map mode setting for showing equipment marker
 * @method
 * @param {ConfigurableMapRouteMode} mapRouteMode
 * @returns {boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowEquipmentMarker } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showEquipmentMarker = useSelector(selectShowEquipmentMarker(constants.mapRouteModes.DISPATCHED));
 */
export const selectShowEquipmentMarker =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.showEquipmentMarker;

/**
 * selects the current map mode setting for showing unassigned tasks cluster mode
 * @method
 * @param {ConfigurableMapRouteMode} mapRouteMode
 * @returns {boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectedIsClusteringUnassignedTasks } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * // this setting is applicable for both plan mode and dispatched route mode
 * // however its value is independent for each route mode
 * const isClusteringUnassignedTasks = useSelector(selectedIsClusteringUnassignedTasks(constants.mapRouteModes.DISPATCHED));
 */
export const selectedIsClusteringUnassignedTasks =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.isClusteringUnassignedTasks;

/**
 * selects the current global map setting whether the map should fit markers to map bounds
 * @method
 * @returns {boolean} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectHasIsolatedRoutes } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map setting
 * const hasIsolatedRoutes = useSelector(selectHasIsolatedRoutes());
 */
export const selectHasIsolatedRoutes = (state: RootState): boolean =>
    state.mapSettings.hasIsolatedRoutes;

/**
 * selects the current global map setting identifying the map marker mode
 * @method
 * @returns {string} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectMapMarkerMode } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map setting
 * const mapMarkerMode = useSelector(selectMapMarkerMode());
 */
export const selectMapMarkerMode = (state: RootState): MapMarkerMode =>
    state.mapSettings.mapMarkerMode;

/**
 * selects the current global map setting identifying the map route mode
 * @method
 * @returns {string} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectMapRouteMode } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map setting
 * const mapRouteMode = useSelector(selectMapRouteMode());
 */
export const selectMapRouteMode = (state: RootState): MapRouteMode =>
    state.mapSettings.mapRouteMode;

/**
 * selects the current global map setting whether the map should fit markers to map bounds
 * @method
 * @returns {boolean} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShouldFitPlanMapToBounds } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map setting
 * const shouldFitPlanMapToBounds = useSelector(selectShouldFitPlanMapToBounds());
 */
export const selectShouldFitPlanMapToBounds = (state: RootState): boolean =>
    state.mapSettings.shouldFitPlanMapToBounds;

/**
 * selects the current global map setting whether the user was redirected to map page
 * @method
 * @returns {boolean} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectRedirectedToMapPage } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map setting
 * const redirectedToMapPage = useSelector(selectRedirectedToMapPage());
 */
export const selectRedirectedToMapPage = (state: RootState): boolean =>
    state.mapSettings.redirectedToMapPage;

/**
 * selects the current map mode setting enabling clustering stops
 * @method
 * @param {ConfigurableMapRouteMode} mapRouteMode
 * @returns {boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectIsClusteringStops } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const isClusteringStops = useSelector(selectIsClusteringStops(constants.mapRouteModes.DISPATCHED));
 */
export const selectIsClusteringStops =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.isClusteringStops;

/**
 * selects the current global map setting whether the map clustering toggle is enabled
 * @method
 * @returns {boolean} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectIsClusteringToggleEnabled } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map setting
 * const isClusteringToggleEnabled = useSelector(selectIsClusteringToggleEnabled());
 */
export const selectIsClusteringToggleEnabled = (state: RootState): boolean =>
    state.mapSettings.isClusteringToggleEnabled;

/**
 * selects the current global map setting whether the map is allowing multiple cards to be selected from the drawers
 * @method
 * @returns {boolean} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectIsMultipleCardSelectEnabled } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map setting
 * const isMultipleCardSelectEnabled = useSelector(selectIsMultipleCardSelectEnabled());
 */
export const selectIsMultipleCardSelectEnabled = (state: RootState): boolean =>
    state.mapSettings.isMultipleCardSelectEnabled;

/**
 * selects the current global map settings
 * @method
 * @returns {MapSettingsState} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectMapSettings } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 *
 * // get the current map setting
 * const mapSettings = useSelector(selectMapSettings);
 */
export const selectMapSettings = (state: RootState): MapSettingsState =>
    state.mapSettings;

/**
 * selects the current global map setting whether the map drawer is set to view card details
 * @method
 * @returns {boolean} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectViewCardDetails } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map setting
 * const viewCardDetails = useSelector(selectViewCardDetails());
 */
export const selectViewCardDetails = (state: RootState): boolean =>
    state.mapSettings.viewCardDetails;

/**
 * selects the current global map setting that determines the active equipment cluster id
 * @method
 * @returns {number} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectActiveEquipmentClusterId } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map setting
 * const activeEquipmentClusterId = useSelector(selectActiveEquipmentClusterId);
 */
export const selectActiveEquipmentClusterId = (state: RootState): number =>
    state.mapSettings.activeEquipmentClusterId;

/**
 * selects the current map mode setting for showing driver actual lines
 * @method selectShowDriverActualLines
 * @param {ConfigurableMapRouteMode} mapRouteMode
 * @returns {boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowDriverActualLines } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showDriverActualLines = useSelector(selectShowDriverActualLines(constants.mapRouteModes.DISPATCHED));
 */
export const selectShowDriverActualLines =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        Boolean(state.mapSettings[mapRouteMode]?.showDriverActualLines);

/**
 * selects the current map mode setting for showing equipment cluster mode
 * @method
 * @param {ConfigurableMapRouteMode} mapRouteMode
 * @returns {boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectIsClusteringEquipment } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const isClusteringEquipment = useSelector(selectIsClusteringEquipment(constants.mapRouteModes.DISPATCHED));
 */
export const selectIsClusteringEquipment =
    (mapRouteMode: ConfigurableMapRouteMode): ((state: RootState) => boolean) =>
    (state: RootState) =>
        state.mapSettings[mapRouteMode]?.isClusteringEquipment;

export default mapSettingsSlice.reducer;
