import { useQuery } from 'react-query';
import { useMapUtils, useFeatureFlag } from '~/hooks';
import { FeatureFlag } from '~/utils/feature-flags-utils';
import RoutePathGeometryApi from '~/api/RoutePathGeometryApi';

export const useRoutePathGeometry = (markers: string) => {
    const queryKey = 'route-path-geometry';
    const { isPlanRouteMode, isDispatchedRouteMode } = useMapUtils();
    const isSnappedToRoadPlannedLinesEnabled = useFeatureFlag(
        FeatureFlag.SNAPPED_TO_ROAD_PLANNED_LINES
    );

    const { data: routePathData, isFetching } = useQuery(
        [
            queryKey,
            markers,
            isPlanRouteMode,
            isSnappedToRoadPlannedLinesEnabled,
            isDispatchedRouteMode
        ],
        async () => {
            const isModeValid = isPlanRouteMode || isDispatchedRouteMode;
            if (!isModeValid || !isSnappedToRoadPlannedLinesEnabled) return;
            const {
                data: { data }
            } = await RoutePathGeometryApi.getPath(markers);
            return data;
        }
    );
    return { routePathData, isFetching };
};
