import { useQuery, UseQueryOptions } from 'react-query';
import TasksV2Api, { GetTasksV2Params } from '~/api/TasksV2Api';
import { ApiLiveDispatchV2 } from '~/api/types';
import taskUtils from '~/utils/task-utils';

type UseGetTasksReactQueryOptions = Omit<
    UseQueryOptions<ApiLiveDispatchV2, Error>,
    'queryKey' | 'queryFn'
>;

interface UseGetTasksParams {
    /**
     * The API options for `TasksV2Api.get()`
     */
    apiOptions: GetTasksV2Params;

    /**
     * Additional react query options
     */
    reactQueryOptions?: UseGetTasksReactQueryOptions;
}

const REACT_QUERY_KEY = 'tasksV2';

export const useGetTasksV2 = ({
    apiOptions = {},
    reactQueryOptions = {}
}: UseGetTasksParams) => {
    const { getTaskStatus } = taskUtils;

    const fetchTasks = async (
        options: GetTasksV2Params = {}
    ): Promise<ApiLiveDispatchV2> => {
        try {
            const response = await TasksV2Api.get(options);

            const {
                data: { data, meta }
            } = response;

            const updatedData = data?.map((apiTask) => {
                const taskStatus = getTaskStatus(apiTask);
                return { ...apiTask, taskStatus };
            });

            const { onSuccess } = reactQueryOptions;

            const apiTaskData = {
                meta,
                data: updatedData
            };

            onSuccess?.(apiTaskData);

            return apiTaskData;
        } catch (error) {
            return {
                data: []
            };
        }
    };

    return useQuery<ApiLiveDispatchV2, Error>(
        [REACT_QUERY_KEY, apiOptions],
        () => fetchTasks(apiOptions),
        reactQueryOptions
    );
};
