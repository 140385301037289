import axios from 'axios';
import constants from '~/utils/constants';
import generalUtils from '~/utils/general-utils';
import {
    AddBreakArgs,
    AxiosApiResponse,
    DeleteBreakArgs,
    EditBreakArgs
} from './types';

export type TaskIdsByRoute = Record<string, string[]>;

interface EvaluateSchedulesResponseData {
    routeId?: string;
    driverId?: string;
    vehicleId?: string;
    tasks?: string[];
    taskIds?: string[];
    taskId?: string;
    schedulerTaskId?: string;
}

interface ClientPreferences {
    /* Date in ISO format */
    date?: string | null;
    driverId?: string;
    driverId1?: string;
    driverId2?: string;
    newRouteId?: string;
    oldRouteId?: string;
    routeId?: string;
    routeIds?: string[];
    taskIds?: string[];
    stopIds?: string[];
    type?: typeof constants.productTypes.SCHEDULER;
    vehicleId?: string;
    numOfLockedTasks?: number;
    routeActions?: unknown;
    taskIdsByRouteId?: TaskIdsByRoute;
}

type BulkUnassignTasksArgs = Pick<
    ClientPreferences,
    'date' | 'taskIds' | 'taskIdsByRouteId'
>;

type UnassignTasksArgs = Pick<
    ClientPreferences,
    'date' | 'routeId' | 'taskIds'
>;
type UnassignTasksResponse = AxiosApiResponse<EvaluateSchedulesResponseData>;

type AugmentRouteArgs = Pick<
    ClientPreferences,
    'date' | 'driverId' | 'taskIds' | 'newRouteId'
>;

type AugmentRouteResponse = AxiosApiResponse<EvaluateSchedulesResponseData>;

interface ResequenceTasksArgs
    extends Pick<
        ClientPreferences,
        'date' | 'routeId' | 'stopIds' | 'taskIds' | 'taskIdsByRouteId'
    > {
    clientPreferences?: ClientPreferences;
    clientId?: string;
}

interface ResequenceTasksResponseData extends EvaluateSchedulesResponseData {
    newStopOrder?: string[];
}

type ResequenceTasksResponse = AxiosApiResponse<ResequenceTasksResponseData>;

interface OptimizeRoutesParams
    extends Pick<ClientPreferences, 'date' | 'routeIds'> {
    clientPreferences?: ClientPreferences;
    clientId?: string;
}

type OptimizeRoutesArgs = OptimizeRoutesParams;

interface OptimizeRoutesResponseData extends EvaluateSchedulesResponseData {
    routeIds?: string[];
}

type OptimizeRoutesResponse = AxiosApiResponse<OptimizeRoutesResponseData>;

interface ReassignTasksArgs
    extends Pick<
        ClientPreferences,
        'date' | 'oldRouteId' | 'taskIdsByRouteId'
    > {
    clientId?: string;
    clientPreferences?: ClientPreferences;
    selectedDriverId?: string;
    selectedRouteId?: string;
}

type ReassignTasksResponse = AxiosApiResponse<EvaluateSchedulesResponseData>;

type DeleteBreakResponse = AxiosApiResponse<EvaluateSchedulesResponseData>;
type AddBreakResponse = AxiosApiResponse<EvaluateSchedulesResponseData>;
type EditBreakResponse = AxiosApiResponse<EvaluateSchedulesResponseData>;

/**
 * Implementations of API methods under the `/schedules/evaluate` path
 *
 * @category API
 */
export default class SinglePlanEditApi {
    /**
     * Path of the single plan edit endpoint
     */
    private static readonly path = '/schedules/evaluate?action=';

    static unassignTasks(
        clientId: string,
        { date, routeId, taskIds }: UnassignTasksArgs
    ): Promise<UnassignTasksResponse> {
        if (!clientId) {
            return Promise.reject('Missing clientId');
        }
        if (!date) {
            return Promise.reject('Missing date');
        }
        if (!routeId) {
            return Promise.reject('Missing routeId');
        }
        if (!taskIds || !Array.isArray(taskIds) || !taskIds.length) {
            return Promise.reject('Missing taskIds');
        }

        const params: ClientPreferences = {
            date,
            routeId,
            taskIds,
            type: constants.productTypes.SCHEDULER
        };

        return axios.post(`${this.path}trim&accept`, params, {
            headers: {
                [constants.requestHeaders.WISE_CLIENT_ID]: clientId
            }
        });
    }

    static bulkUnassignTasks({
        date,
        taskIdsByRouteId,
        taskIds
    }: BulkUnassignTasksArgs): Promise<UnassignTasksResponse> {
        if (!date) {
            return Promise.reject('Missing date');
        }
        if (!taskIdsByRouteId) {
            return Promise.reject('Missing taskIdsByRouteId');
        }
        if (!taskIds || !Array.isArray(taskIds) || !taskIds.length) {
            return Promise.reject('Missing taskIds');
        }

        const params: ClientPreferences = {
            date,
            taskIdsByRouteId,
            taskIds,
            type: constants.productTypes.SCHEDULER
        };

        return axios.post(`${this.path}trim&accept`, params);
    }

    static augmentRoute(
        clientId: string,
        args: AugmentRouteArgs
    ): Promise<AugmentRouteResponse> {
        if (!clientId) {
            return Promise.reject('Missing clientId');
        }
        if (!args.date) {
            return Promise.reject('Missing date');
        }
        if (!args.driverId) {
            return Promise.reject('Missing driverId');
        }

        if (
            !args.taskIds ||
            !Array.isArray(args.taskIds) ||
            !args.taskIds.length
        ) {
            return Promise.reject('Missing taskIds');
        }

        const params: ClientPreferences = {
            ...args,
            type: constants.productTypes.SCHEDULER
        };

        return axios.post(`${this.path}augment&accept`, params, {
            headers: {
                [constants.requestHeaders.WISE_CLIENT_ID]: clientId
            }
        });
    }

    static resequenceTasks({
        clientId,
        clientPreferences,
        date,
        routeId,
        taskIds,
        taskIdsByRouteId
    }: ResequenceTasksArgs): Promise<ResequenceTasksResponse> {
        if (!clientId) {
            return Promise.reject('Missing clientId');
        }
        if (!date) {
            return Promise.reject('Missing date');
        }
        if (!routeId) {
            return Promise.reject('Missing routeId');
        }
        if (!taskIds || !Array.isArray(taskIds) || !taskIds.length) {
            return Promise.reject('Missing taskIds');
        }

        if (!Object.keys(taskIdsByRouteId || {}).length)
            return Promise.reject('Missing task ids breakdown by route ids');

        const params: ClientPreferences = {
            ...clientPreferences,
            date,
            routeId,
            taskIdsByRouteId,
            taskIds,
            type: constants.productTypes.SCHEDULER
        };

        return axios.post(`${this.path}resequence&accept`, params, {
            headers: {
                [constants.requestHeaders.WISE_CLIENT_ID]: clientId
            }
        });
    }

    static optimizeRoutes({
        clientId,
        clientPreferences,
        date,
        routeIds
    }: OptimizeRoutesArgs): Promise<OptimizeRoutesResponse> {
        if (!clientId) return Promise.reject('Missing clientId');

        if (!date) return Promise.reject('Missing date');

        if (!routeIds?.length || !Array.isArray(routeIds))
            return Promise.reject('Missing routeIds');

        const params: ClientPreferences = {
            ...clientPreferences,
            date,
            routeIds,
            type: constants.productTypes.SCHEDULER
        };

        return axios.post(`${this.path}optimize&accept`, params, {
            headers: {
                [constants.requestHeaders.WISE_CLIENT_ID]: clientId
            }
        });
    }

    static reassignTasks({
        clientId,
        clientPreferences,
        date,
        taskIdsByRouteId,
        oldRouteId,
        selectedDriverId,
        selectedRouteId
    }: ReassignTasksArgs): Promise<ReassignTasksResponse> {
        if (!clientId) return Promise.reject('Missing clientId');

        if (!date) return Promise.reject('Missing date');

        if (!oldRouteId) return Promise.reject('Missing oldRouteId');

        if (!Object.keys(taskIdsByRouteId || {}).length)
            return Promise.reject('Missing task ids breakdown by route ids');

        const isNewRouteIdValid =
            selectedRouteId && generalUtils.isValidUUID(selectedRouteId);
        const newRouteId = (isNewRouteIdValid && selectedRouteId) || '';
        const driverId = (!isNewRouteIdValid && selectedDriverId) || '';

        const params: ClientPreferences = {
            ...clientPreferences,
            date,
            driverId,
            newRouteId,
            oldRouteId,
            taskIdsByRouteId,
            type: constants.productTypes.SCHEDULER
        };

        return axios.post(`${this.path}reassign&accept`, params, {
            headers: {
                [constants.requestHeaders.WISE_CLIENT_ID]: clientId
            }
        });
    }

    static addBreak({
        clientId,
        date,
        routeId,
        stopIdBeforeBreak,
        durationSeconds
    }: AddBreakArgs): Promise<AddBreakResponse> {
        if (!clientId) return Promise.reject('Missing clientId');

        if (!date) return Promise.reject('Missing date');

        if (!routeId) return Promise.reject('Missing routeId');

        if (!stopIdBeforeBreak)
            return Promise.reject('Missing stop id before break');

        if (!durationSeconds) return Promise.reject('Missing break duration');

        const params = {
            date,
            routeId,
            stopIdBeforeBreak,
            durationSeconds,
            type: constants.productTypes.SCHEDULER
        };

        return axios.post(`${this.path}add_break&accept`, params, {
            headers: {
                [constants.requestHeaders.WISE_CLIENT_ID]: clientId
            }
        });
    }

    static deleteBreak({
        clientId,
        date,
        routeId,
        breakRuleId
    }: DeleteBreakArgs): Promise<DeleteBreakResponse> {
        if (!clientId) return Promise.reject('Missing clientId');

        if (!date) return Promise.reject('Missing date');

        if (!routeId) return Promise.reject('Missing routeId');

        if (!breakRuleId) return Promise.reject('Missing breakRuleId');

        const params = {
            date,
            routeId,
            breakRuleId,
            type: constants.productTypes.SCHEDULER
        };

        return axios.post(`${this.path}delete_break&accept`, params, {
            headers: {
                [constants.requestHeaders.WISE_CLIENT_ID]: clientId
            }
        });
    }

    static editBreak({
        clientId,
        date,
        routeId,
        breakRuleId,
        stopIdBeforeBreak,
        durationSeconds
    }: EditBreakArgs): Promise<EditBreakResponse> {
        if (!clientId) return Promise.reject('Missing clientId');

        if (!date) return Promise.reject('Missing date');

        if (!routeId) return Promise.reject('Missing routeId');

        if (!breakRuleId) return Promise.reject('Missing breakRuleId');

        // will resequence if `stopIdBeforeBreak` is provided
        // will edit duration if `durationSeconds` is provided
        if (!stopIdBeforeBreak && !durationSeconds)
            return Promise.reject(
                'Must provide either stopIdBeforeBreak or durationSeconds'
            );

        const params = {
            date,
            routeId,
            breakRuleId,
            stopIdBeforeBreak,
            durationSeconds,
            type: constants.productTypes.SCHEDULER
        };

        return axios.post(`${this.path}edit_break&accept`, params, {
            headers: {
                [constants.requestHeaders.WISE_CLIENT_ID]: clientId
            }
        });
    }

    static moveRoute({
        newDate,
        newEid,
        routeDate,
        routeId
    }: {
        newDate: string;
        newEid?: string;
        routeDate: string;
        routeId: string;
    }): Promise<AxiosApiResponse> {
        if (!newDate) return Promise.reject('Missing newDate');

        if (!routeDate) return Promise.reject('Missing routeDate');

        if (!routeId) return Promise.reject('Missing routeId');

        const params = {
            routeId,
            newDate,
            date: routeDate,
            type: constants.productTypes.SCHEDULER,
            ...(Boolean(newEid) && {
                newEid
            })
        };

        return axios.post(`${this.path}postpone&accept`, params);
    }
}
