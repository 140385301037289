import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { resetSelectedMapRoutes } from '~/reducers/selectedMapRoutesSlice';
import { selectTasks } from '~/reducers/tasksSlice';
import {
    resetSelectedMapStops,
    selectSelectedMapStops
} from '~/reducers/selectedMapStopsSlice';

import DispatchTasksControl from './DispatchTasksControl';
import MultiTasksControl from './MultiTasksControl';
import PairTasksControl from './PairTasksControl';
import SingleTasksControl from './SingleTasksControl';
import { ManageStopControl } from '~/ui';
import { mapPlanStopUtils } from '~/utils/map';
import { selectIsOpenLiveDispatchDrawer } from '~/reducers/mapDrawerSettingsSlice';

import './unassigned-tasks-control.scss';

function UnassignedTasksControl() {
    const [showSelectTasksControl, setShowSelectTasksControl] = useState(true);
    const [showDispatchTasksControl, setShowDispatchTasksControl] =
        useState(false);
    const [showPairTasksControl, setShowPairTasksControl] = useState(false);
    const tasksData = useSelector(selectTasks);
    const selectedMapStops = useSelector(selectSelectedMapStops);
    const isOpenLiveDispatchDrawer = useSelector(
        selectIsOpenLiveDispatchDrawer
    );
    const dispatch = useDispatch();
    const { t } = useTranslation('translation');

    const handleClickDeselect = useCallback(() => {
        dispatch(resetSelectedMapStops());
    }, [dispatch]);

    const handleClickDispatch = useCallback(() => {
        setShowSelectTasksControl(false);
        setShowDispatchTasksControl(true);
        setShowPairTasksControl(false);
    }, []);

    const handleClickPair = useCallback(() => {
        setShowSelectTasksControl(false);
        setShowDispatchTasksControl(false);
        setShowPairTasksControl(true);
    }, []);

    const resetControlState = useCallback(() => {
        setShowSelectTasksControl(true);
        setShowDispatchTasksControl(false);
        setShowPairTasksControl(false);
    }, []);

    useEffect(() => {
        // reset back to main control when task selections change
        resetControlState();
    }, [selectedMapStops, resetControlState]);

    const { selectedTasks, shouldRender, isMultiTasksSelected } =
        useMemo(() => {
            const selectedTasksData = mapPlanStopUtils.mapIdtoTasksData(
                selectedMapStops,
                tasksData
            );

            const hasMultiTasksSelected = selectedTasksData.length > 1;

            const canRender =
                selectedTasksData.length > 0 && !isOpenLiveDispatchDrawer;

            return {
                selectedTasks: selectedTasksData,
                shouldRender: canRender,
                isMultiTasksSelected: hasMultiTasksSelected
            };
        }, [selectedMapStops, tasksData, isOpenLiveDispatchDrawer]);

    useEffect(() => {
        // when task selections changed while open,
        // reset `RouteDrawer` selections
        if (shouldRender) {
            dispatch(resetSelectedMapRoutes());
        }
    }, [shouldRender, selectedTasks, dispatch]);

    if (!shouldRender) return null;

    return (
        <ManageStopControl
            className="unassigned-tasks-control"
            data-testid="unassigned-tasks-control"
        >
            {showSelectTasksControl && isMultiTasksSelected && (
                <MultiTasksControl
                    selectedTasks={selectedTasks}
                    onClickDispatchButton={handleClickDispatch}
                    onClickPairButton={handleClickPair}
                    footerButtonTitle={t(
                        'UnassignedTasksControl.button.deselectAll'
                    )}
                    footerButtonIcon="iconClose"
                    onClickFooterButton={handleClickDeselect}
                />
            )}

            {showSelectTasksControl && !isMultiTasksSelected && (
                <SingleTasksControl
                    taskData={selectedTasks[0]}
                    onClickDispatchButton={handleClickDispatch}
                    onClickPairButton={handleClickPair}
                    footerButtonTitle={t(
                        'UnassignedTasksControl.button.deselect'
                    )}
                    footerButtonIcon="iconClose"
                    onClickFooterButton={handleClickDeselect}
                />
            )}

            {showDispatchTasksControl && (
                <DispatchTasksControl
                    selectedTaskIds={selectedTasks.map((task) => task.id)}
                    onClickTitle={resetControlState}
                    modalTitle={t(
                        'UnassignedTasksControl.title.dispatch_stops',
                        { count: selectedTasks.length }
                    )}
                />
            )}

            {showPairTasksControl && (
                <PairTasksControl
                    selectedTaskIds={selectedTasks.map((task) => task.id)}
                    onClickTitle={resetControlState}
                    modalTitle={t('UnassignedTasksControl.title.pair_tasks', {
                        count: selectedTasks.length
                    })}
                />
            )}
        </ManageStopControl>
    );
}

export default UnassignedTasksControl;
