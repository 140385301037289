import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    setIsOpenUnassignedTasksDrawer,
    selectIsOpenUnassignedTasksDrawer
} from '~/reducers/mapDrawerSettingsSlice';
import { selectOnDemandDispatchTaskCount } from '~/reducers/onDemandDispatchTaskCountSlice';
import { IconButton, theme } from '~/ui';
import { useIntermodalFeatures, useMapUtils } from '~/hooks';
import { useLiveRoutesUtils } from '~/hooks/useLiveRoutesUtils';
import { selectUnassignedTasksV2 } from '~/reducers/tasksV2Slice';

import './on-demand-dispatch-map-control.scss';

export default function OnDemandDispatchMapControl() {
    const { deselectDriver } = useLiveRoutesUtils();

    const showDrawerOnDemandDispatch = useSelector(
        selectIsOpenUnassignedTasksDrawer
    );
    const onDemandDispatchTaskCount = useSelector(
        selectOnDemandDispatchTaskCount
    );
    const unassignedTasksV2 = useSelector(selectUnassignedTasksV2);

    const { enableLiveDispatch } = useIntermodalFeatures();

    const { isDispatchedRouteMode } = useMapUtils();
    const dispatch = useDispatch();

    const shouldRender = useMemo(() => {
        const hasUnassignedTasksSelectedDay = onDemandDispatchTaskCount > 0;
        const hasUnassignedTasksAnyDay =
            enableLiveDispatch && unassignedTasksV2?.length;

        return (
            isDispatchedRouteMode &&
            (hasUnassignedTasksSelectedDay || hasUnassignedTasksAnyDay)
        );
    }, [
        isDispatchedRouteMode,
        onDemandDispatchTaskCount,
        unassignedTasksV2,
        enableLiveDispatch
    ]);

    const handleClick = useCallback(() => {
        deselectDriver();
        dispatch(setIsOpenUnassignedTasksDrawer(!showDrawerOnDemandDispatch));
    }, [deselectDriver, dispatch, showDrawerOnDemandDispatch]);

    if (!shouldRender) {
        return null;
    }

    return (
        <IconButton
            className="ondemanddispatchmapcontrol_marker"
            text="?"
            textColor={theme.colors.comet}
            textStyle={{
                ...theme.text['14-medium']
            }}
            style={{
                backgroundColor: theme.colors['galaxy-500']
            }}
            onClick={handleClick}
        />
    );
}
