import { useCallback } from 'react';
import { partition } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import TasksApi from '~/api/TasksApi';
import { PromiseSettledResultStatus, ReactQuerySideEffects } from '~/api/types';
import { useWebInterval } from '~/hooks/useWebInterval';
import { selectDate } from '~/reducers/selectedDateSlice';
import { resetSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';
import { updateTaskMetrics } from '~/reducers/taskMetricsSlice';
import { addNewTask, getAllTasks } from '~/reducers/tasksSlice';
import { addToast } from '~/reducers/toastsSlice';

import {
    SplitTaskResponse,
    SplitTasksSettledDataRecord,
    SplitTasksPromiseFulfilledRecord,
    SplitTasksPromiseFulfilled,
    SplitTasksPromiseRejected,
    SplitTasksSideEffects,
    SplitTasksPromiseRejectedRecord
} from './types';

export const useTaskSplit = () => {
    const selectedDate = useSelector(selectDate);
    const dispatch = useDispatch();
    const { refetch } = useWebInterval();
    const { t } = useTranslation(['taskManagement']);

    const { isLoading: isLoadingSplitTask, mutateAsync: doSplitTaskAsync } =
        useMutation((taskId: string): Promise<SplitTaskResponse> => {
            return TasksApi.splitTask(taskId);
        });

    /**
     * The default `onSuccess` callback for `splitTasks`
     */
    const onSuccessSplitTasks = useCallback(
        (data?: SplitTasksPromiseFulfilledRecord[]) => {
            if (!data) return;

            data.forEach((fulfilledData) => {
                const { settledData } = fulfilledData;

                const {
                    data: {
                        data: { delivery: deliveryTask, pickup: pickupTask }
                    }
                } = settledData.value;

                if (deliveryTask) dispatch(addNewTask(deliveryTask));
                if (pickupTask) dispatch(addNewTask(pickupTask));
            });

            dispatch(
                addToast({
                    message: t('successSplit', {
                        count: data.length
                    }),
                    variant: 'info'
                })
            );
        },
        [dispatch, t]
    );

    /**
     * The default `onError` callback for `splitTasks`
     */
    const onErrorSplitTasks = useCallback(
        (error?: SplitTasksPromiseRejectedRecord[]) => {
            if (!error) return;

            error.forEach((rejectedData) => {
                const {
                    taskId,
                    settledData: { reason }
                } = rejectedData;
                console.error(`Error splitting ${taskId}: ${reason}`);
            });

            dispatch(
                addToast({
                    message: t('error:splitTaskError', {
                        count: error.length
                    }),
                    variant: 'error'
                })
            );
        },
        [dispatch, t]
    );

    /**
     * The default `onSettled` callback for `splitTasks`
     */
    const onSettledSplitTasks = useCallback(() => {
        dispatch(resetSelectedTaskIds([]));
        dispatch(getAllTasks({ routeDate: selectedDate }));
        // eslint-disable-next-line
        // @ts-ignore
        dispatch(updateTaskMetrics(selectedDate));
    }, [dispatch, selectedDate]);

    /**
     * Splits a two-part task into `delivery` and `pickup` tasks
     */
    const splitTask = async (
        taskId: string,
        sideEffects: ReactQuerySideEffects = {}
    ) => {
        const { onSuccess, onError } = sideEffects;

        try {
            const successData = await doSplitTaskAsync(taskId);
            onSuccess?.(successData);
        } catch (error) {
            let message;
            if (error instanceof Error) {
                message = error.message;
            } else {
                message = String(error);
            }
            console.error(message);
            onError?.(error);
        }

        // this operation can modify the live driver data
        // refetch data from socket to ensure that the data is up-to-date
        refetch();
    };

    /**
     * Splits multiple two-part tasks using `Promise.allSettled()`
     *
     * The `onSuccess` side effect callback will be passed all the `fulfilled` responses, when available
     *
     * The `onError` side effect callback will be passed all the `rejected` responses, when available
     *
     * The `onSettled` side effect callback will be passed all the `fulfilled` and `rejected` responses, when available
     */
    const splitTasks = async (
        taskIds: string[],
        sideEffects: SplitTasksSideEffects = {}
    ) => {
        const { onSuccess, onError, onSettled } = sideEffects;

        const settledData = await Promise.allSettled(
            taskIds.map((taskId) => doSplitTaskAsync(taskId))
        );

        const settledDataMap: SplitTasksSettledDataRecord[] = taskIds.map(
            (taskId, idx) => ({ taskId, settledData: settledData[idx] })
        );

        const [fulfilledData, rejectedData] = partition(
            settledDataMap,
            (item) =>
                item.settledData.status === PromiseSettledResultStatus.FULFILLED
        ) as [SplitTasksPromiseFulfilled, SplitTasksPromiseRejected];

        if (fulfilledData.length) onSuccess?.(fulfilledData);
        if (rejectedData.length) onError?.(rejectedData);

        onSettled?.(fulfilledData, rejectedData);

        // this operation can modify the live driver data
        // refetch data from socket to ensure that the data is up-to-date
        refetch();
    };

    return {
        isLoadingSplitTask,
        onSuccessSplitTasks,
        onErrorSplitTasks,
        onSettledSplitTasks,
        splitTask,
        splitTasks
    };
};
