import _ from 'lodash';
import constants from '~/utils/constants';
import {
    ConfigurableMapRouteMode,
    MapSettingsState,
    PerTabMapSettings
} from './types';
import { defaultPerTabMapSettings } from './constants';

export const applyToPerTabSettings = (
    mapRouteMode: ConfigurableMapRouteMode,
    setting: keyof PerTabMapSettings,
    state: MapSettingsState,
    value: boolean | string
): MapSettingsState => {
    if (
        !Object.values(constants.mapRouteModes).includes(mapRouteMode) ||
        !Object.keys(defaultPerTabMapSettings).includes(setting) ||
        !_.isBoolean(value) ||
        typeof value !== typeof state[mapRouteMode][setting]
    ) {
        return state;
    }
    const modeSettings = {
        ...state[mapRouteMode],
        [setting]: value
    };
    return { ...state, [mapRouteMode]: modeSettings };
};
