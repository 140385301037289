import React from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipButton } from '~/ui';
import {
    TooltipButtonSizes,
    TooltipButtonVariants
} from '~/ui/components/TooltipButton';

interface CreateTripModalFooterProps {
    onClose: () => void;
}

export const CreateTripModalFooter = ({
    onClose
}: CreateTripModalFooterProps) => {
    const rootClassName = 'create-trip-modal-footer';
    const { t } = useTranslation('createTripModal');
    const cancelButtonClassName = `${rootClassName}__cancel`;
    const confirmationButtonClassName = `${rootClassName}__confirm`;

    return (
        <div className={`${rootClassName} _d-flex _jc-flex-end`}>
            <TooltipButton
                className={cancelButtonClassName}
                variant={TooltipButtonVariants.SECONDARY}
                size={TooltipButtonSizes.LARGE}
                data-testid={cancelButtonClassName}
                onClick={onClose}
            >
                {t('footer.cancel')}
            </TooltipButton>
            <TooltipButton
                className={confirmationButtonClassName}
                variant={TooltipButtonVariants.PRIMARY}
                size={TooltipButtonSizes.LARGE}
                data-testid={confirmationButtonClassName}
            >
                {t('footer.confirm')}
            </TooltipButton>
        </div>
    );
};
