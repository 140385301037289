import { useSelector } from 'react-redux';
import { selectMainClient } from '~/reducers/mainClientSlice';
import { FeatureFlag } from '~/utils/feature-flags-utils';
import { useFeatureFlag } from '../useFeatureFlag';
import { UseIntermodalFeatures } from './types';
import { carKind } from './carKind';

export const useIntermodalFeatures = (): UseIntermodalFeatures => {
    const mainClient = useSelector(selectMainClient);
    const {
        enableLiveDispatch: isEnabledLiveDispatch,
        enableExternalTaskTypes: isEnabledExternalTaskTypes,
        enableGapInRoute: isEnabledGapInRoute,
        enableResetTasks: isEnabledResetTasks,
        enableOperationalStops: isEnabledOperationalStops
    } = mainClient?.intermodal || {};

    const isFeatureEnabledExternalTaskTypes = useFeatureFlag(
        FeatureFlag.PREDEFINED_EXTERNAL_TASK_TYPES
    );
    const isFeatureEnabledResetTasks = useFeatureFlag(FeatureFlag.RESET_TASKS);
    const isFeatureEnabledOperationalStops = useFeatureFlag(
        FeatureFlag.OPERATIONAL_STOP
    );
    const isFeatureEnabledEditCarKind = useFeatureFlag(
        FeatureFlag.EDIT_CAR_KIND
    );
    const isFeatureEnabledEditEmptyOrder = useFeatureFlag(
        FeatureFlag.EDIT_EMPTY_ORDER_NUMBER
    );
    const isFeatureEnabledShowToDriver = useFeatureFlag(
        FeatureFlag.SHOW_TO_DRIVER
    );

    const enableLiveDispatch = Boolean(isEnabledLiveDispatch);
    const enableExternalTaskTypes = Boolean(
        isEnabledExternalTaskTypes || isFeatureEnabledExternalTaskTypes
    );
    const enableGapInRoute = Boolean(isEnabledGapInRoute);
    const enableResetTasks = Boolean(
        isEnabledResetTasks || isFeatureEnabledResetTasks
    );
    const enableOperationalStops = Boolean(
        isEnabledOperationalStops || isFeatureEnabledOperationalStops
    );
    const enableEditCarKind = Boolean(isFeatureEnabledEditCarKind);
    const enableEditEmptyOrder = Boolean(isFeatureEnabledEditEmptyOrder);
    const enableShowToDriver = Boolean(isFeatureEnabledShowToDriver);

    return {
        carKind,
        enableLiveDispatch,
        enableExternalTaskTypes,
        enableGapInRoute,
        enableResetTasks,
        enableOperationalStops,
        enableEditCarKind,
        enableEditEmptyOrder,
        enableShowToDriver
    };
};
