import { DateTime, Duration } from 'luxon';
import i18n from '~/i18n';
import timeUtils from '~/utils/time-utils';

/**
 * Date Localization
 *
 * @category Utils
 * @module utils/dateUtilsLocalized
 *
 * @example
 * import dateUtilsLocalized from '~/utils/date-utils-localized';
 */

/**
 * Formats the days of the week into single letter
 *
 * @param {string} dayOfWeek - non-abbreviated day of the week (example: 'Monday', 'Tuesday', ...)
 * @returns {string} - single localized letter for day of week (example: 'M', 'T', ...)
 *
 * @example
 * // returns 'M' and 'S' respectively
 * const mondayInitial = dateUtilsLocalized.formatLetterDayOfWeek('Monday');
 * const sundayInitial = dateUtilsLocalized.formatLetterDayOfWeek('Sunday');
 *
 * // returns null
 * const badString = dateUtilsLocalized.formatLetterDayOfWeek('bad string');
 */
function formatLetterDayOfWeek(dayOfWeek: string) {
    const datetime = DateTime.fromFormat(dayOfWeek, 'cccc').setLocale(
        i18n.language
    );
    return datetime.isValid ? datetime.toFormat('ccccc') : null;
}

/**
 * Formats the month and year (example: February 2021)
 *
 * @param {Date} date - JS Date
 * @returns {string} - localized non-abbreviated month and year 'MMMM yyyy'
 */
function formatMonthYear(date: Date) {
    const datetime = DateTime.fromJSDate(date).setLocale(i18n.language);
    return datetime.isValid
        ? datetime.toLocaleString({
              month: 'long',
              year: 'numeric'
          })
        : null;
}

/**
 * Formats the date into abbreviated format (example: Thu, Feb 18)
 *
 * @param {Date} date - JS Date
 * @returns {string} - localized abbreviated date 'EEE MMM d'
 */
function formatAbbreviatedDate(date: Date) {
    const datetime = DateTime.fromJSDate(date).setLocale(i18n.language);
    return datetime.isValid
        ? datetime.toLocaleString({
              weekday: 'short',
              month: 'short',
              day: 'numeric'
          })
        : null;
}
/**
 * Formats the month and day (example: Feb 18)
 *
 * @param {Date} date - JS Date
 * @returns {string} - localized abbreviated date 'MMM d'
 */
function formatMonthDay(date: Date) {
    const datetime = DateTime.fromJSDate(date).setLocale(i18n.language);
    return datetime.isValid
        ? datetime.toLocaleString({
              month: 'short',
              day: 'numeric'
          })
        : null;
}

/**
 * Day of the week int where Sunday = 0 and Saturday = 6 (non-ISO week date)
 *
 * @param {String} date - ISO format date
 * @returns {number} day of week int
 */
function getIntWeekDay(date: string) {
    const day = DateTime.fromISO(date).weekday;
    // convert Sunday int
    if (day === 7) {
        return 0;
    }
    return day || null;
}

function getDuration(startDateTime: DateTime, endDateTime: DateTime) {
    if (
        !DateTime?.isDateTime(startDateTime) ||
        !DateTime?.isDateTime(endDateTime)
    ) {
        return null;
    }
    let duration = endDateTime
        .diff(startDateTime, ['hours', 'minutes'])
        .toObject();

    // when end time is on the next day e.g. 13:00pm - 05:00am
    if ((duration.hours ?? 0) < 0 || (duration.minutes ?? 0) < 0) {
        duration = endDateTime
            .plus({ days: 1 })
            .diff(startDateTime, ['hours', 'minutes'])
            .toObject();
    }

    return Duration.fromObject({
        hours: duration.hours ?? 0,
        minutes: duration.minutes ?? 0
    }).normalize();
}

/**
 * Get duration string in ISO format "PT_H_M"
 *
 * @param {DateTime} startDateTime
 * @param {DateTime} endDateTime
 * @returns {string|null} duration
 */
function getDurationString(startDateTime: DateTime, endDateTime: DateTime) {
    const duration = getDuration(startDateTime, endDateTime);
    return duration?.toISO() || null;
}

/**
 * Adds a duration to an initial time to get a final time
 *
 * @param {String} time - ISO format
 * @param {Object} duration - Duration format { hours: _, minutes: _ }
 * @returns {string} time - local time (example: 18:00)
 */
function addDurationToTime(time: string, duration = { hours: 0, minutes: 0 }) {
    if (!duration || !Duration.fromObject(duration).isValid) {
        return null;
    }

    const dtFromTime = DateTime.fromFormat(time, 'T');
    const dtFromISO = DateTime.fromISO(time);
    const datetime = dtFromTime.isValid ? dtFromTime : dtFromISO;

    return datetime.isValid ? datetime.plus(duration).toFormat('HH:mm') : null;
}

/**
 * Get shift time object from start and duration to {start, end}
 *
 * @param {String} start - start shift time in ISO format (example: "10:00")
 * @param {String} duration - shift duration in ISO format (example: "PT8H")
 * @returns {{start: string, end: string, isLongShift: boolean, hours: number, minutes: number}|null}
 */
function getShiftTime(start: string, duration: string) {
    const shiftStart = DateTime.fromISO(start);
    const shiftDuration = Duration.fromISO(duration);

    if (!shiftStart.isValid || !shiftDuration.isValid) {
        return null;
    }

    const shiftEnd = shiftStart.plus(shiftDuration);
    const maxEndTime = shiftStart.plus(Duration.fromISO('PT23H59M'));
    const shiftDurationObj = shiftDuration
        .shiftTo('hours', 'minutes')
        .normalize()
        .toObject();
    const isLongShift = shiftEnd > maxEndTime;

    const midnight = DateTime.fromISO('00:00').plus({ hours: 24 });
    const isStartBeforeMidnight = shiftStart < midnight;
    const isEndAfterMidnight = shiftEnd >= midnight;
    const isEndNextDay = isStartBeforeMidnight && isEndAfterMidnight;

    return {
        ...shiftDurationObj,
        isEndNextDay,
        isLongShift,
        start: shiftStart.toFormat('HH:mm'),
        end: shiftEnd.toFormat('HH:mm')
    };
}

/**
 * Get localized time (example: "9:00 AM") from ISO date time
 *
 * @param {String} time - time in ISO format
 * @returns {string | null}
 */
function getLocalizedTime(time: string | DateTime) {
    if (typeof time !== 'string') return null;

    const isLocalTime = time.match(/\d{1,2}:\d{2}(:\d{1,2})? (AM|PM)/);
    if (isLocalTime) return time;

    const timeFromIso = DateTime.fromISO(time);
    const isIsoTime = timeFromIso.isValid;
    return isIsoTime ? timeFromIso.toFormat('t') : null;
}

/**
 * Get the formatted time window (example: "10:00 AM - 5:00 PM")
 *
 * @param {string} start - start time in ISO
 * @param {string} end - end time in ISO
 * @returns {string}
 */
function getTimeWindow(start: string, end: string) {
    const startTime = getLocalizedTime(start);
    const endTime = getLocalizedTime(end);
    return `${startTime} - ${endTime}`;
}

/**
 * Compare current time between time windows
 *
 * @param {string} start - start time in ISO
 * @param {string} end - end time in ISO
 * @param {string} timezone
 * @returns {boolean}
 */
function checkIfCurrentTimeIncluded(
    start: string,
    end: string,
    timezone = 'local'
) {
    const currentTime = DateTime.now();
    const startTime = DateTime.fromISO(start, { zone: timezone });
    const endTime = DateTime.fromISO(end, { zone: timezone });
    return currentTime >= startTime && currentTime <= endTime;
}

/**
 * Returns a formatted time window string for the given start and end times in the specified time zone
 *
 * @param {string|Date} start - The start time (string or Date object)
 * @param {string|Date} end - The end time (string or Date object)
 * @param {string} timeZone - The time zone identifier (e.g., 'America/New_York')
 * @param isClientTimezoneFlagEnabled - Flag indicating whether to format the time using the client's time zone
 * @param format - The output string format
 * @returns {string} A formatted string representing the time window in the specified time zone
 */
function getTimeWindowInTimeZone({
    start,
    end,
    timeZone,
    isClientTimezoneFlagEnabled,
    format
}: {
    start: string | DateTime;
    end: string | DateTime;
    isClientTimezoneFlagEnabled: boolean;
    timeZone?: string;
    format?: string;
}) {
    const startTime = timeUtils.formatTimeInTimeZone({
        time: start,
        timeZone,
        isClientTimezoneFlagEnabled,
        format
    });
    const endTime = timeUtils.formatTimeInTimeZone({
        time: end,
        timeZone,
        isClientTimezoneFlagEnabled,
        format
    });
    return `${startTime} - ${endTime}`;
}

export default {
    formatMonthYear,
    formatMonthDay,
    formatLetterDayOfWeek,
    formatAbbreviatedDate,
    getIntWeekDay,
    getDuration,
    getDurationString,
    addDurationToTime,
    getShiftTime,
    getLocalizedTime,
    getTimeWindow,
    checkIfCurrentTimeIncluded,
    getTimeWindowInTimeZone
};
