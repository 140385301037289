import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import root from 'window-or-global';
import { useTranslation } from 'react-i18next';
import { selectCurrentUser } from '~/reducers/currentUserSlice';
import { addToast } from '~/reducers/toastsSlice';
import constants from '~/utils/constants';
import { getStorageEventListener, localStorageKey } from './utils';
import { TaskDetailSetting, TaskDetailsSettings } from './types';

export const useTaskDetailsSettings = () => {
    const [selectedSettings, setSelectedSettings] = useState<
        TaskDetailSetting[]
    >([]);
    const lastSavedSettingsRef = useRef<TaskDetailSetting[]>([]);
    const currentUser = useSelector(selectCurrentUser);
    const { id: currentUserId } = (currentUser ?? {}) as {
        id?: string;
    };
    const dispatch = useDispatch();
    const { t } = useTranslation('taskDetailsSettings');

    useEffect(() => {
        if (!currentUserId) return;

        const localStorageValue =
            root.localStorage.getItem(localStorageKey) || '{}';
        const allSavedSettings = JSON.parse(localStorageValue);
        const savedUserSettings = allSavedSettings[currentUserId] ?? [];

        lastSavedSettingsRef.current = [...savedUserSettings];
        setSelectedSettings(savedUserSettings);

        const listener = getStorageEventListener({
            currentUserId,
            setSelectedSettings,
            lastSavedSettingsRef
        });

        const storageEventType = 'storage';

        window.addEventListener(storageEventType, listener);

        return () => {
            window.removeEventListener(storageEventType, listener);
        };
    }, [currentUserId]);

    const toggleSetting = useCallback((newSetting: TaskDetailSetting) => {
        const updateSettings = (currentSettings: TaskDetailSetting[]) => {
            if (!currentSettings.length) return [newSetting];

            const newSettings = currentSettings.filter(
                (setting) => setting !== newSetting
            );

            if (newSettings.length !== currentSettings.length) {
                return newSettings;
            }

            if (newSettings.length > 2) {
                return [...newSettings.slice(1), newSetting];
            }

            return [...newSettings, newSetting];
        };
        setSelectedSettings(updateSettings);
    }, []);

    const saveSettings = useCallback(() => {
        if (!currentUserId) return;

        const savedSettings = JSON.parse(
            root.localStorage.getItem(localStorageKey) || '{}'
        );
        const data = JSON.stringify({
            ...savedSettings,
            [currentUserId]: selectedSettings
        });

        lastSavedSettingsRef.current = [...selectedSettings];
        root.localStorage.setItem(localStorageKey, data);
        dispatch(
            addToast({
                message: t('toast.updateSuccess'),
                variant: constants.toastVariant.SUCCESS
            })
        );
    }, [currentUserId, dispatch, selectedSettings, t]);

    return useMemo(() => {
        const setOfSettings = new Set(selectedSettings);
        const isContactNameEnabled = setOfSettings.has(
            TaskDetailsSettings.ContactName
        );
        const isInventoryItemEnabled = setOfSettings.has(
            TaskDetailsSettings.InventoryItem
        );
        const isInvoiceEnabled = setOfSettings.has(TaskDetailsSettings.Invoice);
        const isTaskVolumeEnabled = setOfSettings.has(
            TaskDetailsSettings.TaskVolume
        );
        const isTaskWeightEnabled = setOfSettings.has(
            TaskDetailsSettings.TaskWeight
        );

        return {
            isContactNameEnabled,
            isInventoryItemEnabled,
            isInvoiceEnabled,
            isTaskVolumeEnabled,
            isTaskWeightEnabled,
            saveSettings,
            toggleSetting
        };
    }, [saveSettings, selectedSettings, toggleSetting]);
};
