import { useMemo } from 'react';
import { PlanRoute } from '~/data-classes/plan';
import { useSelector } from 'react-redux';
import { selectClients } from '~/reducers/clientsSlice';
import {
    getSummaryMetrics,
    getTitleLine,
    getTripCompartmentStatsByRouteId
} from '~/components/MapPageDrawers/PlanRoutesDrawer/RouteItem/utils';
import { selectAccountSetupByClientId } from '~/reducers/accountSetupByClientSlice';

export const useProcessedRouteData = (route: PlanRoute) => {
    const {
        colorCSS,
        stats,
        isPlanned,
        clientId,
        vehicleEid,
        driverName,
        trips,
        routeId,
        isLocked,
        vehicleMaxVolume,
        vehicleMaxWeight,
        numLateStops,
        numAtRiskStops,
        clientRouteId,
        vehicleId,
        numTasks,
        driverId
    } = route;

    const clients = useSelector(selectClients);

    const clientName = clients[clientId]?.name || '';
    const titleLine = getTitleLine({
        clientName,
        driverName,
        isPlanned,
        vehicleEid
    });

    const { isMultiCompartmentClient = false } =
        useSelector(selectAccountSetupByClientId(clientId)) || {};

    const iconColor = colorCSS.color;
    const markerColor = { backgroundColor: colorCSS.backgroundColor };
    const summaryMetrics = useMemo(() => {
        return getSummaryMetrics({
            stats,
            maxVolumeCapacity: vehicleMaxVolume,
            maxWeightCapacity: vehicleMaxWeight
        });
    }, [stats, vehicleMaxVolume, vehicleMaxWeight]);

    const isMultiTrips = (trips || []).length > 1;

    const singleTripCompartmentStats = useMemo(() => {
        return isMultiTrips
            ? null
            : getTripCompartmentStatsByRouteId(trips, routeId);
    }, [isMultiTrips, trips, routeId]);

    return {
        isMultiTrips,
        singleTripCompartmentStats,
        summaryMetrics,
        markerColor,
        iconColor,
        isMultiCompartmentClient,
        titleLine,
        isLocked,
        clientId,
        trips,
        stats,
        numLateStops,
        numAtRiskStops,
        clientRouteId,
        routeId,
        vehicleId,
        numTasks,
        driverId,
        vehicleCapacity: {
            vehicleMaxWeight,
            vehicleMaxVolume
        }
    };
};
