import {
    useHereMaps,
    useMapUtils,
    useOnDemandDispatchMarkerEventHandler
} from '~/hooks';
import { usePlanMapPropsContext } from '~/components/MapPage/PlanMap/PlanMapPropsContext';
import { useSelector } from 'react-redux';
import { selectDateOnly } from '~/reducers/selectedDateSlice';
import { selectCompletedDrivers } from '~/reducers/liveDriversSlice';
import { selectSelectedDrawerCardData } from '~/reducers/selectedDrawerCardDataSlice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import makeLiveRoutesStopComponents from '~/utils/map-modes/live-routes-stops-mode';
import { ApiLiveStop, Coordinates } from '~/api/types';
import { usePlanMapEventsContext } from '~/components/MapPage/PlanMap/PlanMapEventsContext';
import { selectIsClusteringStops } from '~/reducers/mapSettingsSlice';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';
import { useLiveStopsSuperClusters } from '~/components/MapPage/PlanMap/useLiveStopsSuperClusters';
import { selectLastPlanMapBounds } from '~/reducers/lastPlanMapBoundsSlice';
import { selectLastPlanMapZoom } from '~/reducers/lastPlanMapZoomSlice';

const useCompletedRouteStopMarkersAndLines = () => {
    const { mapRouteMode, isCompletedRouteMode } = useMapUtils();

    // @TODO type PlanMapPropsContext https://wisesys.atlassian.net/browse/RP-840
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { mapInstance } = usePlanMapPropsContext();

    const selectedDate = useSelector(selectDateOnly);
    const completedDrivers = useSelector(selectCompletedDrivers);
    const { data: selectedDrawerCard } = (useSelector(
        selectSelectedDrawerCardData
    ) ?? {}) as {
        data?: {
            id: string;
            schedule?: ApiLiveStop[];
        };
    };

    const { isHerePlanMapActive } = useHereMaps();
    const lastPlanMapZoom = useSelector(selectLastPlanMapZoom);
    const lastPlanMapBounds = useSelector(selectLastPlanMapBounds);

    const [routeStopMarkers, setRouteStopMarkers] = useState<JSX.Element[]>([]);
    const [depotStopMarkers, setDepotStopMarkers] = useState<JSX.Element[]>([]);
    const [routeLines, setSelectedRouteLines] = useState<JSX.Element[]>([]);
    const [routeStopCoordinates, setRouteStopCoordinates] = useState<
        Coordinates[]
    >([]);
    const { emittedEventHandler } = usePlanMapEventsContext();
    const onDemandDispatchMarkerEventHandler =
        useOnDemandDispatchMarkerEventHandler();
    const isClusteringStops = useSelector(
        selectIsClusteringStops(mapRouteMode as ConfigurableMapRouteMode)
    );
    const { superClusters } = useLiveStopsSuperClusters();

    const getClusters = useCallback(
        (superCluster) => {
            if (isHerePlanMapActive) {
                return superCluster.points;
            }
            return superCluster.getClusters(lastPlanMapBounds, lastPlanMapZoom);
        },
        [isHerePlanMapActive, lastPlanMapBounds, lastPlanMapZoom]
    );

    useEffect(() => {
        if (!selectedDate) {
            return;
        }
        setRouteStopMarkers([]);
        setSelectedRouteLines([]);
        setRouteStopCoordinates([]);
        setDepotStopMarkers([]);
    }, [selectedDate]);

    const { id: selectedDrawerCardId } = selectedDrawerCard ?? {};
    const selectedDriver = useMemo(() => {
        if (!selectedDrawerCardId) return;

        return completedDrivers.find(({ id }) => id === selectedDrawerCardId);
    }, [completedDrivers, selectedDrawerCardId]);

    useEffect(() => {
        if (
            !isCompletedRouteMode ||
            !selectedDriver ||
            !selectedDrawerCard?.schedule
        ) {
            return;
        }

        const {
            stopMarkers,
            routeLines: selectedRouteLines,
            depotMarkers
        } = makeLiveRoutesStopComponents({
            selectedDriver,
            mapInstance,
            mapRouteMode,
            onDemandDispatchMarkerEventHandler,
            emittedEventHandler,
            liveStopsSuperClusters: superClusters,
            getClusters,
            isClusteringStops
        });
        setRouteStopMarkers(stopMarkers);
        setDepotStopMarkers(depotMarkers);
        setSelectedRouteLines(selectedRouteLines);

        const routeStopCoords = selectedDrawerCard.schedule.map(
            (item: ApiLiveStop) => {
                return item.location.location;
            }
        );
        setRouteStopCoordinates(routeStopCoords);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [
        mapRouteMode,
        mapInstance,
        isCompletedRouteMode,
        selectedDrawerCard,
        selectedDriver,
        getClusters,
        isClusteringStops
    ]);

    return {
        depotStopMarkers,
        routeStopMarkers,
        routeStopCoordinates,
        routeLines
    };
};

export default useCompletedRouteStopMarkersAndLines;
