import { setCepResult } from '~/reducers/schedulerProgressSlice';
import {
    getAllTasks,
    removeTasksById,
    updateTaskRefresh
} from '~/reducers/tasksSlice';
import { store } from '~/store';
import { removePendingJob } from '~/reducers/pendingJobsSlice';
import { planEditMetricsService } from '~/services/planEditMetricsService';
import { notifyReceivedWebSolution } from '~/reducers/detectWebSolutionSlice';
import throttleRequestsHandler from '~/utils/socket/throttle-requests';
import dateUtils from '~/utils/date-utils';

import i18n from '~/i18n';
import { addToast } from '~/reducers/toastsSlice';
import { resetSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';
import {
    addOnDemandDispatchTask,
    removeOnDemandDispatchTask
} from '~/reducers/onDemandDispatchTaskCountSlice';
import { WebMultiClientApi } from '~/api/WebMultiClientApi';
import { addUploadResult } from '~/reducers/uploadResultsSlice';
import { setCustomerSearchResults } from '~/reducers/customerSearchResultsSlice';
import { setWebSolution } from '~/reducers/webSolutionSlice';

const dispatchWebSolution = (payloadData) => {
    store.dispatch(setWebSolution(payloadData));
};

function handleWebSolution(payload) {
    const storeState = store.getState();
    const jobId = payload.data.job;
    const { data: payloadData } = payload;

    if (storeState.pendingJobs && storeState.pendingJobs[jobId]) {
        dispatchWebSolution(payloadData);
        store.dispatch(removePendingJob(jobId));
        store.dispatch(notifyReceivedWebSolution());
    } else {
        const {
            data: { routeDate: taskRouteDate }
        } = payloadData;
        const routeDate = dateUtils.convertToISODateOnly(
            storeState.selectedDate
        );
        if (routeDate === taskRouteDate) {
            dispatchWebSolution(payloadData);
            store.dispatch(notifyReceivedWebSolution());
            throttleRequestsHandler.clearRequestThrottle();
        }
    }
}

function handleCepResults(payload) {
    store.dispatch(setCepResult(payload));
}

function handleUpload(payload) {
    store.dispatch(addUploadResult(payload));
}

async function handleDirectRouteImpact(payload) {
    const storeState = store.getState();
    const { selectedDate, planClientsLevelData } = storeState;

    if (!selectedDate) {
        return;
    }

    const routeDate = dateUtils.convertToISODateOnly(selectedDate);

    // check if clientId_schedulerTaskId matches up
    try {
        const clientTaskCount = planClientsLevelData.reduce(
            (result, client) => {
                const { clientId, stats } = client;
                return clientId === payload.clientId ? stats.numTasks : result;
            },
            0
        );

        throttleRequestsHandler.setRequest(
            payload.clientId,
            routeDate,
            clientTaskCount
        );

        await planEditMetricsService.addMetricsToStore(
            payload.clientId,
            payload.data.schedulerTaskId
        );
    } catch (e) {
        console.error(e);
    }
}

function handlePairingExecuted(payload) {
    const { pairing, unPairedDeliveryIds, unPairedPickupIds } =
        payload.data.pairing;

    const counts = {
        pairsCreatedCount: pairing.length,
        deliveryTasksRemainedCount: unPairedDeliveryIds?.length || 0,
        pickupTasksRemainedCount: unPairedPickupIds?.length || 0
    };

    store.dispatch(
        addToast({
            message: i18n.t('taskManagement:pairedUnassignedTasks', counts),
            variant: 'info'
        })
    );
    store.dispatch(getAllTasks({ routeDate: payload.date }));
    store.dispatch(resetSelectedTaskIds());
}

function _handleSingleOnDemandTaskChange(action, routeDate) {
    const isToday = routeDate && dateUtils.isSameDayOrToday(routeDate);
    if (isToday) store.dispatch(action());
}

function handleOnDemandTaskAdded(data) {
    const { routeDate } = data;
    // increment unassigned tasks count
    _handleSingleOnDemandTaskChange(addOnDemandDispatchTask, routeDate);
}

function handleOnDemandTaskRemoved(data) {
    const { routeDate, id } = data;
    // decrement unassigned tasks count
    _handleSingleOnDemandTaskChange(removeOnDemandDispatchTask, routeDate);
    store.dispatch(removeTasksById([id]));
}

/**
 * Handles the `tasks-indexed` socket event
 *
 * Queues the `useLiveDispatchUnassignedTasksV2` hook to update via
 * the `tasks/tasksListRefresh` redux selector
 */
function handleTaskIndexUpdate() {
    store.dispatch(updateTaskRefresh(true));
}

async function handleOnDemandTaskAddedBulk() {
    try {
        const storeState = store.getState();
        const { activeClients } = storeState;
        const clientIds = Object.keys(activeClients);
        await WebMultiClientApi.get(clientIds);
    } catch (err) {
        console.error('Unexpected error while getting web', err);
    }
}

function handleCustomerSearch(payload) {
    store.dispatch(setCustomerSearchResults(payload.data));
}

function handleCustomerSearchError() {
    store.dispatch(
        addToast({
            message: i18n.t('error:customerSearchError'),
            variant: 'error'
        })
    );
}

async function handleEndAdjustment(clientId) {
    const storeState = store.getState();
    const { activeClients } = storeState;
    const isActiveClient = activeClients[clientId];

    if (!isActiveClient) return;
    await WebMultiClientApi.get([clientId]);
}

export default {
    handleCepResults,
    handleCustomerSearch,
    handleCustomerSearchError,
    handleDirectRouteImpact,
    handleOnDemandTaskAdded,
    handleOnDemandTaskAddedBulk,
    handleTaskIndexUpdate,
    handleOnDemandTaskRemoved,
    handlePairingExecuted,
    handleUpload,
    handleWebSolution,
    handleEndAdjustment
};
