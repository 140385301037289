import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetTasksV2Params } from '~/api/TasksV2Api';
import { ApiTask, OpenSearchAttributeCount } from '~/api/types';
import type { RootState } from '~/store';

interface TasksV2State {
    attributes?: Record<string, OpenSearchAttributeCount[]>;
    unassignedTasks: ApiTask[];
    totalUnassignedTasksV2Count: number;
    totalUnassignedTasksV2PageCount: number;
    tasksV2PagiationOptions: GetTasksV2Params;
}

const MAX_PAGE_SIZE = 100;
const DEAFULT_PAGINATION_OPTIONS = {
    limit: MAX_PAGE_SIZE,
    page: 0
};

const initialState: TasksV2State = {
    unassignedTasks: [],
    totalUnassignedTasksV2Count: 0,
    totalUnassignedTasksV2PageCount: 0,
    tasksV2PagiationOptions: DEAFULT_PAGINATION_OPTIONS
};

export const tasksV2Slice = createSlice({
    name: 'tasksV2',
    initialState,
    reducers: {
        setUnassignedTasksV2: (
            state: TasksV2State,
            action: PayloadAction<ApiTask[]>
        ) => {
            return {
                ...state,
                unassignedTasks: action.payload
            };
        },
        setTasksV2Attributes: (
            state: TasksV2State,
            action: PayloadAction<Record<string, OpenSearchAttributeCount[]>>
        ) => {
            return {
                ...state,
                attributes: action.payload
            };
        },
        setTotalUnassignedTasksV2Count: (
            state: TasksV2State,
            action: PayloadAction<number>
        ) => {
            return {
                ...state,
                totalUnassignedTasksV2Count: action.payload
            };
        },
        setTotalUnassignedTasksV2PageCount: (
            state: TasksV2State,
            action: PayloadAction<number>
        ) => {
            return {
                ...state,
                totalUnassignedTasksV2PageCount: action.payload
            };
        },
        setTasksV2ApiPaginationOptions: (
            state: TasksV2State,
            action: PayloadAction<GetTasksV2Params>
        ) => {
            return {
                ...state,
                tasksV2PagiationOptions: action.payload
            };
        }
    }
});

export const {
    setTasksV2Attributes,
    setUnassignedTasksV2,
    setTotalUnassignedTasksV2Count,
    setTotalUnassignedTasksV2PageCount,
    setTasksV2ApiPaginationOptions
} = tasksV2Slice.actions;

export const selectTasksV2Attributes = (
    state: RootState
): Record<string, OpenSearchAttributeCount[]> | undefined =>
    state.tasksV2.attributes;

export const selectUnassignedTasksV2 = (state: RootState): ApiTask[] =>
    state.tasksV2.unassignedTasks;

export const selectTotalUnassignedTasksV2Count = (state: RootState): number =>
    state.tasksV2.totalUnassignedTasksV2Count;

export const selectTotalUnassignedTasksV2PageCount = (
    state: RootState
): number => state.tasksV2.totalUnassignedTasksV2PageCount;

export const selectTasksV2ApiPaginationOptions = (
    state: RootState
): GetTasksV2Params => state.tasksV2.tasksV2PagiationOptions;

export default tasksV2Slice.reducer;
