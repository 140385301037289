import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { selectSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import { selectShowStopNumber } from '~/reducers/mapSettingsSlice';
import constants from '~/utils/constants';
import { Icon } from '~/ui';
import { useTranslation } from 'react-i18next';
import { useStopMarkerLabel } from './useStopMarkerLabel';
import eventBus from '~/EventBus';
import { selectSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';

import './stopmarker.scss';

function StopMarker({
    className,
    number = '-1',
    label = '',
    colorCSS = {},
    data = {},
    isPlanned,
    isHighPriority,
    lat,
    lng,
    mapRouteMode = constants.mapRouteModes.PLAN,
    emittedEventHandler = () => {},
    customLabel = undefined
}) {
    const { t } = useTranslation('common');

    const [isDisabled, setIsDisabled] = useState(false);
    const selectedMapStops = useSelector(selectSelectedMapStops);
    const selectedTaskIds = useSelector(selectSelectedTaskIds);
    const showStopNumber = useSelector(selectShowStopNumber(mapRouteMode));

    const isSelected =
        selectedMapStops.includes(data.clientRouteTaskId) ||
        selectedTaskIds.includes(data.id);

    const { isAtRisk, isLate, taskType, isUnassigned, isDelivery } = data;

    const stopMarkerLabel = useStopMarkerLabel({
        customLabel,
        label,
        mapRouteMode
    });

    useEffect(() => {
        setIsDisabled(typeof emittedEventHandler !== 'function');
    }, [emittedEventHandler]);

    function _handleMouseEnter() {
        const payload = {
            location: {
                lat,
                lng
            },
            stopMarkerData: data
        };
        eventBus.publish(constants.mapChildEvents.STOP_MOUSEENTER, payload);

        emittedEventHandler({
            event: constants.mapChildEvents.STOP_MOUSEENTER,
            payload
        });
    }

    function _handleDragStart() {
        emittedEventHandler({
            event: constants.mapChildEvents.STOP_DRAGSTART,
            payload: {
                isSelected,
                isPlanned,
                id: data.clientRouteTaskId,
                combinedIds: [data.clientRouteTaskId],
                clientRouteId: data.clientRouteId,
                type: constants.mapChildEvents.STOP_DRAGSTART,
                selectedMapStops
            }
        });
    }

    function _handleMouseUp() {
        const payload = {
            isSelected,
            isTwoPart: data.isTwoPart,
            isPlanned,
            id: data.clientRouteTaskId,
            routeDate: data.routeDate,
            clientRouteId: data.clientRouteId,
            selectedMapStops,
            taskId: data.taskId,
            stopLevelData: data.toJSON()
        };
        eventBus.publish(constants.mapChildEvents.STOP_MOUSEUP, payload);
        emittedEventHandler({
            event: constants.mapChildEvents.STOP_MOUSEUP,
            payload
        });
    }

    function _getClassName() {
        let boxClassName = 'stopmarker';
        boxClassName =
            (isSelected && `${boxClassName} stopmarker_selected`) ||
            boxClassName;
        boxClassName =
            (isHighPriority && `${boxClassName} stopmarker-star`) ||
            boxClassName;
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }

    function getIndicatorIconClass() {
        const boxClassName = 'stopmarker__icon _p-absolute';

        const conditionalClasses = {
            'icon--priority': isHighPriority,
            'icon--default': !isHighPriority
        };
        return classNames(boxClassName, conditionalClasses);
    }

    function getIndicatorIcon() {
        if (!isAtRisk && !isLate) return null;
        return (
            <Icon
                className={getIndicatorIconClass()}
                icon="clockFill3"
                color={isAtRisk ? 'venus' : 'mars'}
                size="s"
                stroke="comet"
                data-testid="clock-fill-icon"
            />
        );
    }

    const getUnassignedTaskStopIcon = () => {
        const isDeliveryTask =
            taskType === constants.taskTypes.DELIVERY || isDelivery;

        return isDeliveryTask
            ? t('unassignedTaskIcons.delivery')
            : t('unassignedTaskIcons.pickup');
    };

    function _getMarkerBody() {
        if (isHighPriority) {
            return (
                <>
                    <Icon
                        color={colorCSS.backgroundColor}
                        fillOpacity={colorCSS.opacity || 1}
                        icon="stopPriority"
                        size="xl"
                        data-testid="stop-marker-proprity"
                    />
                    {showStopNumber && (
                        <span className="stopmarker-star_number">{number}</span>
                    )}
                    {getIndicatorIcon()}
                </>
            );
        }
        return (
            <div className="_p-relative" data-testid="default-stop-marker">
                <span
                    className="stopmarker-circle"
                    data-testid="stop-marker-circle"
                    style={colorCSS}
                >
                    {isUnassigned
                        ? getUnassignedTaskStopIcon()
                        : showStopNumber && number}
                </span>
                {getIndicatorIcon()}
            </div>
        );
    }

    return (
        <div className="_d-grid">
            <button
                type="button"
                className={_getClassName()}
                onMouseEnter={_handleMouseEnter}
                draggable="true"
                onDragStart={_handleDragStart}
                onMouseUp={_handleMouseUp}
                disabled={isDisabled}
                data-testid="stop-marker"
            >
                <div className="stopmarker-body">{_getMarkerBody()}</div>
            </button>
            {stopMarkerLabel}
        </div>
    );
}

export default StopMarker;
