import constants from '~/utils/constants';
import { MapSettingsState, PerTabMapSettings } from './types';

export const defaultPerTabMapSettings: PerTabMapSettings = {
    showZonePolygons: false,
    showRoutePolygons: false,
    showRoutePopup: false,
    showRouteLabel: false,
    showStopPopup: false,
    showStopNumber: false,
    showStopLabel: false,
    showLiveRoutesPopup: false,
    showLiveRoutesLabel: false,
    showDriverLines: false,
    showEquipmentMarker: false,
    showVehicleEid: false,
    showDriverName: false,
    isClusteringUnassignedTasks: false,
    isZonePolygonsEditMode: false,
    showDriverActualLines: false,
    isClusteringEquipment: false,
    isClusteringStops: false
};

export const defaultGlobalMapSettings: MapSettingsState = {
    hasIsolatedRoutes: false,
    mapMarkerMode: constants.mapMarkerModes.ROUTES,
    mapRouteMode: constants.mapRouteModes.EMPTY,
    shouldFitPlanMapToBounds: false,
    redirectedToMapPage: false,
    isClusteringToggleEnabled: true,
    isMultipleCardSelectEnabled: false,
    viewCardDetails: false,
    activeUnassignedTasksClusterId: -1,
    activeEquipmentClusterId: -1,
    [constants.mapRouteModes.PLAN]: {
        ...defaultPerTabMapSettings,
        showDriverLines: true,
        showStopNumber: true
    },
    [constants.mapRouteModes.DISPATCHED]: {
        ...defaultPerTabMapSettings,
        showDriverLines: true,
        showStopNumber: true,
        showStopPopup: true,
        showDriverActualLines: true
    },
    [constants.mapRouteModes.COMPLETED]: {
        ...defaultPerTabMapSettings,
        showDriverLines: true,
        showStopNumber: true,
        showStopPopup: true,
        showDriverActualLines: true
    }
};

export const stateObjectKeyMap = {
    showZonePolygons: 'showZonePolygons',
    isZonePolygonsEditMode: 'isZonePolygonsEditMode'
} as const;
